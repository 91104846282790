import queryString from "query-string"
import { getEnums } from "./localstorage"
import dayjs from "dayjs"
import { iconWarning } from "./icons"

export function convertPermission(permission) {
  const list = Object.keys(permission)
  let objPermission = {}
  for (let i = 0; i < list.length; i++) {
    let __path = list[i]?.split(".")[0]
    let values = permission[list[i]]
    if (!objPermission[__path]) objPermission[__path] = {}
    for (let i = 0; i < values.length; i++) {
      if (values[i].method === "*") {
        objPermission[__path] = {
          DELETE: true,
          POST: true,
          PUT: true,
          GET: true,
          CLOSE: true,
          DETAIL: true,
          ATRACT: true,
          APPROVAL: true,
          VIEW: true
        }
        break
      } else {
        objPermission[__path][values[i].method] = true
      }
    }
  }
  return objPermission
}
export function convertModal({ text, t }) {
  return {
    className: "modal_confirm",
    icon: null,
    centered: true,
    title: (
      <div>
        <div className="flex justify-center">{iconWarning}</div>
        <div className="text-center">{t("I2149_11382;611_93748")}</div>
      </div>
    ),
    cancelText: t("I2402_13777;611_95467;30_32137"),
    content: <div className="text-center text-gray-500">{text}</div>,
    okText: t("I2402_13777;611_95468;611_95349")
  }
}
export function downloadObjectAsJson(exportObj, exportName) {
  var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj))
  var downloadAnchorNode = document.createElement("a")
  downloadAnchorNode.setAttribute("href", dataStr)
  downloadAnchorNode.setAttribute("download", exportName + ".json")
  document.body.appendChild(downloadAnchorNode)
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

export function convertDurationToClock(n) {
  let h = Math.floor(n / 3600)
  let m = n % 3600
  let _m = Math.floor(m / 60)
  let s = m % 60
  if (h === 0) {
    h = ""
  } else {
    h = h + ":"
  }
  if (_m < 10) _m = "0" + _m
  if (s < 10) s = "0" + s
  return h + _m + ":" + s
}

export function convertTranslates(array) {
  let resource = {}
  for (let i = 0; i < array.length; i++) {
    resource[array[i].file_element.element_id] = array[i].translation_content
  }
  return resource
}

export default function convertTransalte() {
  let translates = {
    en: "English"
  }
  const translatesStore = localStorage.getItem("language")
  if (translatesStore) translates = JSON.parse(translatesStore)
  let rows = Object.keys(translates).map((i) => {
    return {
      label: translates[i],
      value: i
    }
  })
  return rows
}

export function converDataTree(_list, filter) {
  let list = [..._list]
  function loop(__list) {
    for (let i = 0; i < __list.length; i++) {
      __list[i].value = __list[i].id
      __list[i].title = __list[i].name

      if (__list[i].children && __list[i].children.length > 0) {
        if (filter) {
          __list[i].selectable = true
        } else {
          __list[i].selectable = false
        }
        loop(__list[i].children)
      }
    }
  }
  loop(list)
  return list
}

export const paramsUrl = {
  get: () => {
    return queryString.parse(window.location.search)
  },
  set: (params) => {
    const currentUrlParams = queryString.stringify(params, {
      skipNull: true
    })
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}?${currentUrlParams.toString()}`
    )
  }
}

export function getSearchParams() {
  return queryString.parse(window.location.search)
}

export function getRole() {
  const role = localStorage.getItem("role")
  return role || false
}

export function fomatCurrency(number) {
  if (number) {
    return parseInt(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
  }
  return 0
}

export function getToken() {
  return localStorage.getItem("accessToken")
}

export function getLocalOrg() {
  return JSON.parse(JSON.parse(localStorage.getItem("baseData")).value).user.organization
}

export function convertEnumToDropdown(key) {
  const enums = getEnums()
  return enums[key]
}

export async function downloadFile(r) {
  if (!r?._id || !r.file_name.split(".")[1]) return
  const response = await fetch(r?.url, { method: "GET" })
  let blob = await response.blob()
  // blob.name = "xxxx.csv"
  const urlDownload = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = urlDownload
  link.setAttribute("download", convertFileName(r))
  link.click()
}

export function convertFileName(r) {
  if (!r?._id) return false
  let names = r.file_name.split("_")
  let fileType = r.file_name.split(".")[1]
  let newName = names[0] + "_" + names[1] + "_" + dayjs(r?.created_at).format("DD_MM_YYYY_HH_mm_ss")

  if (fileType) newName = newName + "." + fileType

  return newName
}
