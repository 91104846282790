import { SERVICE_CODE } from "lib/Const"
import getInstanceAxios from "utils/request"

const baseDomain = process.env.REACT_APP_API_GATEWAY_URL
const baseURL = `${baseDomain}/${SERVICE_CODE.API_ASSET_CONTROL}/`

export default function ClientChauffeur(resource, method, action, requestId) {
  return getInstanceAxios(
    baseURL,
    resource,
    SERVICE_CODE.API_ASSET_CONTROL,
    method,
    undefined,
    action,
    requestId
  )
}
