import { Button, Avatar, Badge } from "antd"
import moment from "moment-timezone"
// import { useEffect, useState } from "react"
import { Approval_Status } from "lib/Const"
import { UserOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"

export default function BoxApproval({ item, onConfirm }) {
  const { data, created_at, content, body, _id, is_read, loading } = item
  const { t } = useTranslation()

  const handleOk = async (e, v) => {
    e.stopPropagation()
    onConfirm(data?.id, v, _id, is_read)
  }

  return (
    <div>
      <div className="flex gap-4">
        <div>
          <Badge dot={!is_read ? true : false} offset={[-5, 1]}>
            <Avatar icon={<UserOutlined />} />
          </Badge>
        </div>
        <div>
          {/* <div className="font-semibold line-clamp-1">{title}</div> */}
          <div className="line-clamp-2">{body}</div>
          <div className="text-xs">{moment(created_at).format("DD-MM-Y HH:mm")}</div>
        </div>
      </div>

      <div>{content}</div>
      {/* {data?.status === Approval_Status.in_process && !is_read && ( */}
      <div className="flex gap-4 justify-start mt-3">
        <Button
          type="primary"
          onClick={(e) => handleOk(e, true)}
          loading={loading}
          disabled={data?.status === Approval_Status.in_process && !is_read ? false : true}
        >
          {t("I2006_4187;30_32021")}
        </Button>
        <Button
          type="primary"
          danger
          onClick={(e) => handleOk(e, false)}
          loading={loading}
          disabled={data?.status === Approval_Status.in_process && !is_read ? false : true}
        >
          {t("2278_11230")}
        </Button>
      </div>
      {/* )} */}
    </div>
  )
}
