import Client from "../client/ClientNotification"
import version from "../version"
const resource = version + "/notification"

function getCountNoti(params) {
  return Client().get(`${resource}/count/`, { params })
}
function getNotificationByCategory(params) {
  return Client().get(`${resource}/alls/`, { params })
}
function getCategories(params) {
  return Client().get(`${resource}/categories/`, { params })
}
function get(params) {
  return Client().get(`${resource}/`, { params })
}
function create(data) {
  return Client().post(`${resource}/`, data)
}
function markReadByNoti(id) {
  return Client().put(`${resource}/markread/?noti_id=${id}`)
}

const api = {
  getCountNoti,
  getNotificationByCategory,
  get,
  create,
  markReadByNoti,
  getCategories
}
export default api
