import Client from "../client/ClientAssetControl"
import version from "../version"
const resource = version + "/staff/organization"

function gets(params) {
  return Client("root_ac_organization.ac_organization_path_view").get(`${resource}/list/`, {
    params
  })
}
function get(id, params) {
  return Client("root_ac_organization.ac_organization_path_detail").get(
    `${resource}/retrieve/${id}/localization/`,
    {
      params
    }
  )
}
function update(id, data) {
  return Client("root_ac_organization.ac_organization_path_update").put(
    `${resource}/retrieve/${id}/localization/`,
    data
  )
}

const api = {
  gets,
  get,
  update
}
export default api
