import { Suspense, useEffect } from "react"
import { Outlet, BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import Layout from "components/common/Layout"
import { Result } from "antd"
import SuspenseComponent from "./SuspenseComponent"
import pageList from "./PageList"
import Login from "pages/Authen/Login"
import { useStore } from "components/ui"
import { RESOURCE_KEY } from "lib/Const"
import { apiUser } from "api"

function ProtectedRoute() {
  return (
    <Layout>
      <Suspense fallback={<SuspenseComponent />}>
        <Outlet />
      </Suspense>
    </Layout>
  )
}

async function init(setAuthStore) {
  const _userInfo = await apiUser.getUserInfo()
  let baseData = {
    user: _userInfo?.data
  }
  setAuthStore(baseData)
}

export default function Routers() {
  const { access_token, permission, setAuthStore } = useStore()

  let tabId = sessionStorage.tabID

  useEffect(() => {
    if (access_token && tabId) {
      init(setAuthStore)
    }
  }, [])

  return (
    <Router>
      <Routes>
        {access_token && permission && tabId ? (
          <>
            <Route element={<ProtectedRoute />}>
              {pageList().map(({ Element, path, resource, pageName }, key) => {
                const actionPermission = permission[resource] || {}
                if (actionPermission["GET"] || resource === RESOURCE_KEY.ROOT_BYPASS) {
                  return (
                    <Route
                      path={path}
                      key={key}
                      element={<Element actionPermission={actionPermission} />}
                    />
                  )
                }
              })}
              <Route path="*" element={<NotLoadAuthorization />} />
            </Route>
          </>
        ) : (
          <Route element={<Outlet />}>
            <Route path="*" element={<Login />} />
          </Route>
        )}
      </Routes>
    </Router>
  )
}

const NotLoadAuthorization = () => {
  return (
    <div className="__content">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Link to="/">Back Home</Link>}
      />
    </div>
  )
}
