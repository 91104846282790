import Client from "../client/ClientAssetControl"
import version from "../version"
const resource = version + "/staff/vehicle"
const resourceSetting = version + "/staff/vehicle-setting"

function gets(params) {
  return Client("root_vehicle.vehicle_path_view").get(`${resource}/list/`, {
    params
  })
}
function getSettings(params) {
  return Client("root_vehicle_setting.vehicle_setting_path_view").get(`${resourceSetting}/list/`, {
    params
  })
}
function getFilterSettings(params) {
  return Client("root_vehicle_setting.vehicle_setting_path_view").get(
    `${resourceSetting}/filter/`,
    {
      params
    }
  )
}
function createSetting(body) {
  return Client("root_vehicle_setting.vehicle_setting_path_view").post(
    `${resourceSetting}/create/`,
    body
  )
}
function getInternalServices() {
  return Client("root_service.service_path_view").get(`${version}/internal/internal-services/`)
}
function get(id) {
  return Client("root_service.service_path_detail").get(`${resource}/retrieve/${id}/`)
}
function getSetting(id) {
  return Client("root_service.service_path_detail").get(`${resourceSetting}/retrieve/${id}/`)
}
function create(data) {
  return Client("root_service.service_path_create").post(`${resource}/create/`, data)
}
function update(id, data) {
  return Client("root_service.service_path_update").put(`${resource}/retrieve/${id}/`, data)
}
function updateStatus(id, data) {
  return Client("root_service.service_path_update").put(
    `${resource}/retrieve/${id}/update-status`,
    data
  )
}
function remove(id) {
  return Client("root_service.service_path_delete").delete(`${resource}/retrieve/${id}/`)
}
function getLogs(id, params) {
  return Client().get(`${resource}/retrieve/${id}/operation-logs/`, { params })
}

function updateSetting(id, data) {
  return Client("root_service.service_path_update").put(`${resourceSetting}/retrieve/${id}/`, data)
}
function removeSetting(id) {
  return Client("root_service.service_path_delete").delete(`${resourceSetting}/retrieve/${id}/`)
}
function exportVehicle(data) {
  return Client("root_service.service_path_detail").post(`${resource}/export/`, data)
}
function exportVehicleSetting(params) {
  return Client("root_service.service_path_detail").get(`${resourceSetting}/export/`, { params })
}
function requestApproval(data, action, requestId) {
  return Client("root_service.service_path_create", undefined, action, requestId).post(
    `${resource}/request-approval/`,
    data
  )
}

const api = {
  gets,
  getSettings,
  getFilterSettings,
  getInternalServices,
  get,
  create,
  update,
  remove,
  getLogs,
  createSetting,
  getSetting,
  updateSetting,
  exportVehicle,
  updateStatus,
  exportVehicleSetting,
  removeSetting,
  requestApproval
}
export default api
