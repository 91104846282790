export const DEVICE_STATUS = {
  SENT: 0,
  RECEIVED: 1,
  DELIVERED: 2,
  RETURNING: 3,
  INSTOCK: 4,
  INTRANSIT: 5,
  RETURNED: 6
}

export const DEVICE_STATUS_NAME = [
  "Sent",
  "Received",
  "Delivered",
  "Returning",
  "In-stock",
  "In-transit",
  "Returned"
]

export const SEND_DEVICES = [
  DEVICE_STATUS.SENT,
  DEVICE_STATUS.INSTOCK,
  DEVICE_STATUS.DELIVERED,
  DEVICE_STATUS.RETURNING
]

export const RECEIVE_DEVICES = [
  DEVICE_STATUS.INTRANSIT,
  DEVICE_STATUS.RECEIVED,
  DEVICE_STATUS.RETURNED
]

export const SIM_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0
}

export const SIM_STATUS_NAME = ["Active", "Inactive"]

export const sendDeviceStatus = {
  0: { label: "I2067_6669;75_53940;75_53853;75_53818", color: "" },
  1: { label: "I2067_6669;75_53940;75_53854;75_53818", color: "orange" },
  2: { label: "I2067_6669;75_53940;75_53855;75_53818", color: "#108ee9" },
  3: { label: "I2067_6669;75_53940;75_53856;75_53818", color: "#f50" }
}

export const receiveDeviceStatus = {
  1: { label: "I2067_7185;75_53940;75_53853;75_53818", color: "orange" },
  2: { label: "I2067_7185;75_53940;75_53854;75_53818", color: "#108ee9" },
  3: { label: "I2067_7185;75_53940;75_53855;75_53818", color: "#f50" }
}
