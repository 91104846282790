import Client from "../client/ClientAssetControl"
import version from "../version"
const resource = version + "/staff/reminder"

function gets(params) {
  return Client("root_reminder.reminder_path_view").get(`${resource}/list/`, { params })
}
function update(data) {
  return Client("root_reminder.reminder_path_update").post(`${resource}/update/`, data)
}

const api = {
  gets,
  update
}
export default api
