import { SERVICE_CODE } from "lib/Const"
import getInstanceAxios from "utils/request"

const baseDomain = process.env.REACT_APP_API_GATEWAY_URL
const baseURL = `${baseDomain}/${SERVICE_CODE.API_NOTI_INAPP}/`

// export default getInstanceAxios(baseURL)
export default function clientGateWay(action, requestId) {
  return getInstanceAxios(baseURL, undefined, undefined, undefined, undefined, action, requestId)
}
