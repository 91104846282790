import { Modal, Button } from "antd"
import { useTranslation } from "react-i18next"
import { iconWarning } from "lib/icons"

export function ModalConfirm({ visible, content, onCancel, onSubmit, loading }) {
  const { t } = useTranslation()

  return (
    <Modal
      className="modal_confirm modal-warning"
      title={
        <div>
          <div className="flex justify-center">{iconWarning}</div>
          <div className="text-center">Would you like to continue your session?</div>
        </div>
      }
      centered
      width={416}
      open={visible}
      onOk={onSubmit}
      closable={false}
      footer={
        <div className="flex gap-4 justify-center mt-10">
          <Button onClick={() => onCancel()}>{t("10_3457")}</Button>
          <Button
            style={{ backgroundColor: "#FAAD14", boxShadow: "none" }}
            type="primary"
            loading={loading}
            onClick={() => onSubmit()}
          >
            {t("I2382_12944;30_32021")}
          </Button>
        </div>
      }
    ></Modal>
  )
}
