import Client from "../client/ClientControlPlane"
import version from "../version"
const resource = version + "/internal/organizations"

function gets(params) {
  return Client("root_idm_organization.idm_organization_path_view", "VIEW").get(`${resource}/`, {
    params
  })
}
function get(id) {
  return Client("root_idm_organization.idm_organization_path_detail").get(`${resource}/${id}/`)
}
function create(data) {
  return Client("root_idm_func_setting.idm_func_setting_path_create").post(`${resource}/`, data)
}
function update(id, data) {
  return Client("root_idm_func_setting.idm_func_setting_path_update").put(
    `${resource}/${id}/`,
    data
  )
}
function remove(id) {
  return Client("root_idm_organization.idm_organization_path_delete").delete(`${resource}/${id}/`)
}
function updateFunction(organization_id, data) {
  return Client("root_idm_func_setting.idm_func_setting_path_update").put(
    `${resource}/${organization_id}/update_organization_settings/`,
    data
  )
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  updateFunction
}
export default api
