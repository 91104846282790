import { Button } from "antd"
import { useNavigate } from "react-router-dom"
export default function MyPagination({ title, canGoBack = true, children }) {
  const navigate = useNavigate()
  const navigatePrePage = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate("/", { replace: true })
    }
  }
  return (
    <div className="flex items-center mb-2">
      {canGoBack ? (
        <Button
          type="text"
          size="large"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeMiterlimit={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
          }
          onClick={navigatePrePage}
        />
      ) : null}

      <div className="flex justify-between items-center gap-4 w-full">
        <div className="font-semibold text-4xl text-gray-950">{title}</div>
        {children}
      </div>
    </div>
  )
}
