export const iconWarning = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="#FAAD14"
    className="w-10 h-10"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
    />
  </svg>
)
export const iconLogout = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.61538 18C3.15513 18 2.77083 17.8458 2.4625 17.5375C2.15417 17.2292 2 16.8449 2 16.3846V3.61537C2 3.15512 2.15417 2.77083 2.4625 2.4625C2.77083 2.15417 3.15513 2 3.61538 2H10.0192V3H3.61538C3.46154 3 3.32052 3.0641 3.1923 3.1923C3.0641 3.32052 3 3.46154 3 3.61537V16.3846C3 16.5385 3.0641 16.6795 3.1923 16.8077C3.32052 16.9359 3.46154 17 3.61538 17H10.0192V18H3.61538ZM14.4615 13.5385L13.7596 12.8192L16.0788 10.5H7.1923V9.5H16.0788L13.7596 7.18078L14.4615 6.46152L18 10L14.4615 13.5385Z"
      fill="black"
      fillOpacity="0.65"
    />
  </svg>
)
