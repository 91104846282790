import Client from "../client/ClientNotification"
import version from "../version"

const resource = version + "/send_notification"

function gets(params) {
  return Client().get(`${resource}/view/`, { params })
}
function get(id) {
  return Client().get(`${resource}/detail/${id}/`)
}
function create(data) {
  return Client().post(`${resource}/create/`, data)
}
function update(id, data) {
  return Client().put(`${resource}/update/${id}/`, data)
}
function remove(id, params) {
  return Client().delete(`${resource}/delete/${id}/`, { params })
}
function requestApproval(data, action, requestId) {
  return Client(action, requestId).post(`${resource}/request-approval/`, data)
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  requestApproval
}
export default api
