import Client from "../client/ClientControlPlane"
import version from "../version"
const resource = version + "/internal/projects"

function gets(params) {
  return Client("root_project_resource.project_resource_path_view").get(`${resource}/`, {
    params
  })
}
function get(id) {
  return Client("root_project_resource.project_resource_path_detail").get(`${resource}/${id}/`)
}
function create(data) {
  return Client("root_project_resource.project_resource_path_create").post(`${resource}/`, data)
}
function update(id, data) {
  return Client("root_project_resource.project_resource_path_update").put(
    `${resource}/${id}/`,
    data
  )
}
function remove(id, params) {
  return Client("root_project_resource.project_resource_path_delete").delete(`${resource}/${id}/`, {
    params
  })
}
function updateOrder(data) {
  return Client("root_project_resource.project_resource_path_update").put(
    `${resource}/reorder/`,
    data
  )
}
function getDashBoard(project_id) {
  return Client("root_project_resource.project_resource_path_view").get(
    `${version}/internal/project-dashboard/${project_id}/view/`
  )
}

function getLinkageService(params) {
  return Client("root_project_resource.project_resource_path_view").get(
    `${version}/internal/linkage-service/`,
    {
      params
    }
  )
}

function getPermissions(params) {
  return Client("root_project_resource.project_resource_path_view").get(
    `${version}/internal/permissions/`,
    {
      params
    }
  )
}
const api = {
  gets,
  get,
  create,
  update,
  remove,
  updateOrder,
  getDashBoard,
  getLinkageService,
  getPermissions
}
export default api
