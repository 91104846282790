import Client from "../client/ClientControlPlane"
import version from "../version"

const login = (data) => {
  return Client("root_admin_user.admin_user_path_view").post(`/api/v1/account/login/`, data)
}
function gets(data) {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/slides`, data)
}
function create(data) {
  return Client("root_admin_user.admin_user_path_create").post(`${version}/slides/`, data)
}
function update(id, data) {
  return Client("root_admin_user.admin_user_path_update").put(
    `${version}/account/password/${id}/`,
    data
  )
}
function remove(id) {
  return Client("root_admin_user.admin_user_path_delete").delete(
    `${version}/account/password/${id}/`
  )
}
function getMenus(params) {
  return Client("root_admin_user.admin_user_path_view").get(
    `${version}/internal/menus-admin/`,
    params
  )
}
function getEnums(data) {
  return Client("root_admin_user.admin_user_path_view").get(
    `${version}/internal/enum_seleted_data/`
  )
}
function getUserInfo(data) {
  return Client("root_admin_user.admin_user_path_view").get(
    `${version}/account/view/profile/`,
    data
  )
}
const api = {
  login,
  gets,
  create,
  update,
  remove,
  getMenus,
  getEnums,
  getUserInfo
}
export default api
