import Client from "../client/ClientControlPlane"
import ClientIDM from "../client/ClientControlIDM"

import version from "../version"
const resource = version + "/internal/dashboard"

function gets(params) {
  return Client("root_dashboard_control.dashboard_control_path_view").get(`${resource}s/`, {
    params
  })
}
function getDashboard() {
  return Client("root_dashboard_control.dashboard_control_path_view").get(`${resource}/`)
}
function getDashboardIDM() {
  return ClientIDM("root_dashboard_idm.dashboard_idm_path_view").get(`${version}/user/dashboard/`)
}
function create(data) {
  return Client("root_dashboard_control.dashboard_control_path_create").post(`${resource}/`, data)
}
function update(id, data) {
  return Client("root_dashboard_control.dashboard_control_path_update").put(
    `${resource}/${id}/`,
    data
  )
}
function remove(id) {
  return Client("root_dashboard_control.dashboard_control_path_delete").delete(`${resource}/${id}/`)
}

const api = {
  gets,
  getDashboard,
  create,
  update,
  remove,
  getDashboardIDM
}
export default api
