// import Client from "../client/ClientControlPlane"
import version from "../version"
import ClientChauffeur from "../client/ClientAssetControl"
const resource = version + "/staff"

function gets(params) {
  return ClientChauffeur("root_device.device_path_view").get(`${resource}/device/list/`, { params })
}
function getAvailableDevices(params) {
  return ClientChauffeur().get(`${resource}/device/available/`, { params })
}
function getDetailDevice(id) {
  return ClientChauffeur().get(`${resource}/device/retrieve/${id}/`)
}
function createDevice(data) {
  return ClientChauffeur("root_service.service_path_create").post(
    `${resource}/device/create/`,
    data
  )
}
function updateDevice(id, data) {
  return ClientChauffeur("root_service.service_path_update").put(
    `${resource}/device/retrieve/${id}/`,
    data
  )
}

function removeDevice(id, data) {
  return ClientChauffeur("root_service.service_path_update").delete(
    `${resource}/device/retrieve/${id}/`,
    data
  )
}

function updateStatusDevice(id, data) {
  return ClientChauffeur().put(`${resource}/device/retrieve/${id}/update-status`, data)
}

function exportDevice(data) {
  return ClientChauffeur().post(`${resource}/device/export/`, data)
}

function getLogs(id, params) {
  return ClientChauffeur().get(`${resource}/device/retrieve/${id}/operation-logs/`, { params })
}
function getSims(params) {
  return ClientChauffeur().get(`${version}/staff/sim/list/`, { params })
}
function getSimsAvailabe(params) {
  return ClientChauffeur().get(`${version}/staff/sim/available/`, { params })
}
function getDetailSim(id) {
  return ClientChauffeur().get(`${version}/staff/sim/retrieve/${id}/`)
}

function updateStatusSim(id, data) {
  return ClientChauffeur().put(`${resource}/sim/retrieve/${id}/update-status`, data)
}

function importSim(formData) {
  return ClientChauffeur().post(`${resource}/sim/import/`, formData)
}

function remove(id) {
  return ClientChauffeur("root_service.service_path_delete").delete(`${resource}/${id}/`)
}

function updateMutil(data) {
  return ClientChauffeur("root_service.service_path_update").put(
    `${resource}/change-status/multiple/`,
    data
  )
}
function removeMutil(data) {
  return ClientChauffeur("root_service.service_path_delete").put(
    `${resource}/delete/multiple/`,
    data
  )
}
// Sim
function createSim(data) {
  return ClientChauffeur("root_service.service_path_create").post(`${resource}/sim/create/`, data)
}
function updateSim(id, data) {
  return ClientChauffeur("root_service.service_path_create").put(
    `${resource}/sim/retrieve/${id}/update-connected-status`,
    data
  )
}
function removeSim(id) {
  return ClientChauffeur("root_service.service_path_create").delete(
    `${resource}/sim/retrieve/${id}/`
  )
}
function requestApprovalSim(data, action, requestId) {
  return ClientChauffeur("root_service.service_path_create", undefined, action, requestId).post(
    `${resource}/sim/request-approval/`,
    data
  )
}

//Telecom Company

async function getTelecomCompanies(params) {
  return ClientChauffeur().get(`${resource}/telecom-vendor/list/`, { params })
}

function createTelecomCompany(data) {
  return ClientChauffeur("root_service.service_path_create").post(
    `${resource}/telecom-vendor/create/`,
    data
  )
}
function updateTelecomCompany(id, data) {
  return ClientChauffeur("root_service.service_path_update").put(
    `${resource}/telecom-vendor/retrieve/${id}/`,
    data
  )
}
function getTelecomCompany(id) {
  return ClientChauffeur("root_service.service_path_update").get(
    `${resource}/telecom-vendor/retrieve/${id}/`
  )
}
function removeTelecomCompany(id) {
  return ClientChauffeur("root_service.service_path_delete").delete(
    `${resource}/telecom-vendor/retrieve/${id}/`
  )
}
function requestApprovalTelecomCompany(data, action, requestId) {
  return ClientChauffeur("root_service.service_path_create", undefined, action, requestId).post(
    `${resource}/telecom-vendor/request-approval/`,
    data
  )
}

function getOrganizations(params) {
  return ClientChauffeur("root_service.service_path_update").get(
    `${resource}/organization/autocomplete/`,
    { params }
  )
}

const api = {
  gets,
  getAvailableDevices,
  getDetailDevice,
  updateStatusDevice,
  exportDevice,
  getOrganizations,
  getSims,
  getDetailSim,
  getSimsAvailabe,
  createDevice,
  updateDevice,
  removeDevice,
  remove,
  getLogs,
  createSim,
  updateSim,
  updateStatusSim,
  updateMutil,
  removeSim,
  requestApprovalSim,
  importSim,
  removeMutil,
  getTelecomCompanies,
  createTelecomCompany,
  updateTelecomCompany,
  getTelecomCompany,
  removeTelecomCompany,
  requestApprovalTelecomCompany
}
export default api
