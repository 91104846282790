import { DEVICE_STATUS_NAME, SIM_STATUS_NAME } from "./enum"

export function getPermissions() {
  return localStorage.getItem("permissions") ? localStorage.getItem("permissions") : "[]"
}

export function getDeviceStatus(status) {
  return DEVICE_STATUS_NAME[status] || "-"
}

export function getSimStatus(status) {
  return SIM_STATUS_NAME[status] || "-"
}

export function slug(text) {
  return text
    .toLowerCase()
    .replace(/[^\w\s-]/g, "") // Xóa ký tự không phải chữ cái, số, dấu gạch dưới hoặc dấu gạch ngang
    .replace(/\s+/g, "-") // Thay thế khoảng trắng bằng dấu gạch ngang
    .replace(/--+/g, "-") // Loại bỏ các dấu gạch ngang liên tiếp
    .trim() // Loại bỏ các khoảng trắng dư thừa ở đầu và cuối chuỗi
}

export function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}
