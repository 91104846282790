import Client from "../client/ClientGateway"
import version from "../version"
const resource = version + "/master/wrapper-groups"

function gets(params) {
  return Client.get(`${resource}`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}`)
}
function create(data) {
  return Client.post(`${resource}`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}`)
}

const api = {
  gets,
  get,
  create,
  update,
  remove
}
export default api
