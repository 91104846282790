import { SERVICE_CODE, configLocale } from "lib/Const"
import getInstanceAxios from "utils/request"

// The base domain is obtained from the environment variables.
const baseDomain = process.env.REACT_APP_API_GATEWAY_URL

// The base URL is constructed using the base domain.
const baseURL = `${baseDomain}/${SERVICE_CODE.API_AMS}/`

/**
 * Custom function for creating an Axios instance with specific configurations for IDM API.
 * @param {string} resource - The API resource path.
 * @param {string} method - The HTTP method (GET, POST, PUT, DELETE, etc.).
 * @returns {AxiosInstance} - Axios instance configured for IDM API.
 */
export default function ClientAms(resource) {
  // Returns an Axios instance configured with IDM API specific settings.
  return getInstanceAxios(baseURL, resource, SERVICE_CODE.API_AMS, "GET", configLocale.projectId)
}
